<template>
    <v-container fluid class="stories-page">
  
      <!-- Блок skeleton-плейсхолдера. Показывается, пока isLoadingUser === true -->
      <div v-if="isLoadingUser">
        <v-skeleton-loader type="image, text, text"></v-skeleton-loader>
        <p>Загружаем данные о пользователе... Пожалуйста, подождите.</p>
      </div>
  
      <!-- Основной контент (v-else), когда isLoadingUser === false -->
      <div v-else>
  
        <!-- Заголовок -->
        <v-row>
          <v-col>
            <h1>Управление сторисами</h1>
          </v-col>
        </v-row>
  
        <!-- Фильтры -->
        <v-row class="filters d-flex flex-row" no-gutters dense>
  
          <!-- Фильтр "Статус" -->
          <v-col cols="auto">
            <v-select
              v-model="filters.status"
              :items="statusItems"
              item-value="value"
              item-text="text"
              label="Статус"
              @change="applyFilters"
              dense
              outlined
              clearable
            ></v-select>
          </v-col>
  
          <!-- Фильтр "Поставщик" (прячем, если isProvider = true) -->
          <v-col v-if="!isProvider" cols="auto">
            <v-select
              v-model.number="filters.providerId"
              :items="providerSelectItems"
              item-value="id"
              item-text="label"
              label="Поставщик"
              clearable
              outlined
              dense
              @change="applyFilters"
            ></v-select>
          </v-col>
  
          <!-- Фильтр "Филиал" -->
          <v-col cols="auto">
            <v-select
              v-model.number="filters.departmentId"
              :items="departmentSelectItems"
              item-value="id"
              item-text="label"
              label="Филиал"
              clearable
              outlined
              dense
              @change="applyFilters"
            ></v-select>
          </v-col>
  
          <!-- Кнопка "Обновить список" -->
          <v-col cols="auto">
            <v-btn color="primary" @click="fetchStories" block>
              Обновить список
            </v-btn>
          </v-col>
  
          <!-- Кнопка "Добавить сторис" -->
          <v-col cols="auto" class="d-flex align-center justify-end">
            <v-btn
              color="success"
              class="add-story-btn"
              style="margin-bottom: 30px;"
              @click="openCreateModal"
            >
              Добавить сторис
            </v-btn>
          </v-col>
        </v-row>
  
        <!-- Список сторисов -->
        <v-row class="stories-list">
          <v-col
            v-for="story in filteredStories"
            :key="story.id"
            cols="12" sm="6" md="4" lg="2"
          >
            <v-card class="story-card" outlined>
              <v-card-text>
                <div class="card-logo">
                  <img
                    v-if="story.logoUrl"
                    :src="story.logoUrl"
                    alt="Логотип"
                    style="max-height:100px;"
                  />
                  <div v-else>Нет лого</div>
                </div>
                <div class="card-info">
                  <h3>{{ story.storyName }}</h3>
                  <p>
                    Поставщик:
                    {{ getProviderNameById(story.providerId) }}
                    <span v-if="story.providerId"></span>
                  </p>
                  <p>
                    Филиал:
                    {{ getDepartmentNameById(story.departmentId) }}
                    <span v-if="story.departmentId"></span>
                  </p>
                  <p>
                    Цвет шапки:
                    <span class="color-indicator" :style="{ backgroundColor: story.headerColor }"></span>
                    {{ story.headerColor }}
                  </p>
                  <p>Статус: {{ story.isActive ? "Активен" : "Неактивен" }}</p>
                </div>
              </v-card-text>
  
              <v-card-actions class="card-actions" style="flex-wrap: wrap; gap: 8px">
                <v-btn color="primary" small @click="openEditModal(story)">Редактировать</v-btn>
                <v-btn color="info" small @click="openPreviewModal(story)">Предпросмотр</v-btn>
                <v-btn color="error" small @click="confirmDelete(story)">Удалить</v-btn>
                <v-btn color="warning" small @click="toggleStatus(story)">
                  {{ story.isActive ? "Отключить" : "Включить" }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
  
        <!-- =========== Модалка Создания =========== -->
        <v-dialog v-model="showCreateModal" persistent max-width="800px">
          <v-card>
            <v-card-title>
              <h2>Создать сторис</h2>
            </v-card-title>
            <v-card-text>
              <v-form @submit.prevent="createStory">
                <!-- Поставщик -->
                <div class="form-group">
                  <label>Поставщик:</label>
                  <v-select
                    v-model.number="newStory.providerId"
                    :items="isProvider ? providerSelectSingle : providerSelectItems"
                    item-value="id"
                    item-text="label"
                    label="Выберите поставщика"
                    required
                    outlined
                    dense
                    :disabled="isProvider"
                  ></v-select>
                </div>
  
                <!-- Филиал -->
                <div class="form-group">
                  <label>Филиал:</label>
                  <v-select
                    v-model.number="newStory.departmentId"
                    :items="departmentSelectItems"
                    item-value="id"
                    item-text="label"
                    label="Выберите филиал"
                    required
                    outlined
                    dense
                  ></v-select>
                </div>
  
                <div class="form-group">
                  <label>Название сториса:</label>
                  <v-text-field
                    v-model="newStory.storyName"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </div>
  
                <!-- Страницы (новая) -->
                <div class="pages-section">
                  <h3>Страницы</h3>
                  <div
                    v-for="(page, pIndex) in newStory.pages"
                    :key="pIndex"
                    class="page-block"
                  >
                    <h4>Страница #{{ pIndex + 1 }}</h4>
                    <div class="form-group">
                      <label>Выберите товар для страницы:</label>
                      <v-autocomplete
                        v-model="selectedNewProductForPage[pIndex]"
                        :items="productsForSelectItems"
                        item-value="id"
                        item-text="name"
                        label="Товар"
                        outlined
                        dense
                        clearable
                      ></v-autocomplete>
                      <v-btn
                        color="primary"
                        small
                        class="mt-1"
                        @click="addProductToNewPage(pIndex)"
                      >
                        Добавить товар
                      </v-btn>
                    </div>
                    <div class="selected-products">
                      <div
                        v-for="prodId in page.productIds"
                        :key="prodId"
                        class="selected-product"
                      >
                        <span>{{ getProductName(prodId) }}</span>
                        <button
                          type="button"
                          @click="removeProductFromNewPage(pIndex, prodId)"
                        >
                          ×
                        </button>
                      </div>
                    </div>
                    <p>Товаров: {{ page.productIds.length }} / 6</p>
                    <v-btn
                      v-if="pIndex !== 0"
                      color="error"
                      small
                      class="remove-page-btn mt-2"
                      @click="removeNewPage(pIndex)"
                    >
                      Удалить страницу
                    </v-btn>
                  </div>
                  <v-btn
                    v-if="newStory.pages.length < 4"
                    color="secondary"
                    small
                    class="mt-2"
                    @click="addNewPage"
                  >
                    Добавить страницу
                  </v-btn>
                </div>
  
                <div class="form-group mt-4">
                    <label>Цвет шапки:</label>

                    <!-- Меню для colorPicker -->
                    <v-menu
                        v-model="showColorMenuCreate" 
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="newStory.headerColor"
                            label="Цвет (HEX)"
                            prepend-inner-icon="mdi-palette"
                            outlined
                            dense
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>

                        <!-- В самом меню размещаем color picker -->
                        <v-color-picker
                        v-model="newStory.headerColor"
                        hide-canvas
                        mode="hexa"
                        flat
                        dot-size="20"
                        ></v-color-picker>

                        <v-card-actions class="d-flex justify-end pa-2">
                        <v-btn color="primary" text @click="showColorMenuCreate = false">
                            Готово
                        </v-btn>
                        </v-card-actions>
                    </v-menu>
                    </div>
  
                <div class="form-group">
                  <label>Логотип сториса (обязательно):</label>
                  <v-file-input
                    @change="handleCreateLogoChange"
                    required
                    prepend-icon="mdi-file-image"
                    accept="image/*"
                    outlined
                    dense
                    label="Загрузите файл"
                  ></v-file-input>
                  <div v-if="newStoryLogoPreview" class="logo-preview">
                    <img :src="newStoryLogoPreview" alt="Логотип" />
                  </div>
                </div>
  
                <div class="form-group">
                  <label>Активность:</label>
                  <v-switch
                    v-model="newStory.isActive"
                    label="Активен?"
                    inset
                    dense
                  ></v-switch>
                </div>
  
                <div class="form-actions mt-4">
                  <v-btn color="info" @click="openCreatePreview">Предпросмотр</v-btn>
                  <v-btn color="success" type="submit">Создать</v-btn>
                  <v-btn text @click="closeCreateModal">Отмена</v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
  
        <!-- =========== Модалка Редактирования =========== -->
        <v-dialog v-model="showEditModal" persistent max-width="800px">
          <v-card>
            <v-card-title>
              <h2>Редактировать сторис</h2>
            </v-card-title>
            <v-card-text>
              <v-form @submit.prevent="updateStory">
                <!-- Поставщик -->
                <div class="form-group">
                  <label>Поставщик:</label>
                  <v-select
                    v-model.number="editStory.providerId"
                    :items="isProvider ? providerSelectSingle : providerSelectItems"
                    item-value="id"
                    item-text="label"
                    label="Выберите поставщика"
                    required
                    outlined
                    dense
                    @change="onProviderChangeEdit"
                    :disabled="isProvider"
                  ></v-select>
                </div>
  
                <!-- Филиал -->
                <div class="form-group">
                  <label>Филиал:</label>
                  <v-select
                    v-model.number="editStory.departmentId"
                    :items="departmentSelectItems"
                    item-value="id"
                    item-text="label"
                    label="Выберите филиал"
                    required
                    outlined
                    dense
                  ></v-select>
                </div>
  
                <div class="form-group">
                  <label>Название сториса:</label>
                  <v-text-field
                    v-model="editStory.storyName"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </div>
  
                <!-- Страницы (редактирование) -->
                <div class="pages-section">
                  <h3>Страницы</h3>
                  <div
                    v-for="(page, pIndex) in editStory.pages"
                    :key="pIndex"
                    class="page-block"
                  >
                    <h4>Страница #{{ pIndex + 1 }}</h4>
                    <div class="form-group">
                      <label>Выберите товар для страницы:</label>
                      <v-autocomplete
                        v-model="selectedEditProductForPage[pIndex]"
                        :items="productsForSelectItems"
                        item-value="id"
                        item-text="name"
                        label="Товар"
                        outlined
                        dense
                        clearable
                      ></v-autocomplete>
                      <v-btn
                        color="primary"
                        small
                        class="mt-1"
                        @click="addProductToEditPage(pIndex)"
                      >
                        Добавить товар
                      </v-btn>
                    </div>
                    <div class="selected-products">
                      <div
                        v-for="prodId in page.productIds"
                        :key="prodId"
                        class="selected-product"
                      >
                        <span>{{ getProductName(prodId) }}</span>
                        <button
                          type="button"
                          @click="removeProductFromEditPage(pIndex, prodId)"
                        >
                          ×
                        </button>
                      </div>
                    </div>
                    <p>Товаров: {{ page.productIds.length }} / 6</p>
                    <v-btn
                      v-if="pIndex !== 0"
                      color="error"
                      small
                      class="remove-page-btn mt-2"
                      @click="removeEditPage(pIndex)"
                    >
                      Удалить страницу
                    </v-btn>
                  </div>
                  <v-btn
                    v-if="editStory.pages.length < 4"
                    color="secondary"
                    small
                    class="mt-2"
                    @click="addEditPage"
                  >
                    Добавить страницу
                  </v-btn>
                </div>
  
                <div class="form-group mt-4">
                    <label>Цвет шапки:</label>

                    <!-- Меню для colorPicker -->
                    <v-menu
                        v-model="showColorMenuCreate" 
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="newStory.headerColor"
                            label="Цвет (HEX)"
                            prepend-inner-icon="mdi-palette"
                            outlined
                            dense
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>

                        <!-- В самом меню размещаем color picker -->
                        <v-color-picker
                        v-model="newStory.headerColor"
                        hide-canvas
                        mode="hexa"
                        flat
                        dot-size="20"
                        ></v-color-picker>

                        <v-card-actions class="d-flex justify-end pa-2">
                        <v-btn color="primary" text @click="showColorMenuCreate = false">
                            Готово
                        </v-btn>
                        </v-card-actions>
                    </v-menu>
                    </div>
  
                <div class="form-group">
                  <label>Логотип (опционально):</label>
                  <v-file-input
                    @change="handleEditLogoChange"
                    prepend-icon="mdi-file-image"
                    accept="image/*"
                    outlined
                    dense
                    label="Загрузите файл"
                  ></v-file-input>
                  <div v-if="editStoryLogoPreview" class="logo-preview">
                    <img :src="editStoryLogoPreview" alt="Логотип" />
                  </div>
                </div>
  
                <div class="form-group">
                  <label>Активность:</label>
                  <v-switch
                    v-model="editStory.isActive"
                    label="Активен?"
                    inset
                    dense
                  ></v-switch>
                </div>
  
                <div class="form-actions mt-4">
                  <v-btn color="info" @click="openEditPreview">Предпросмотр</v-btn>
                  <v-btn color="success" type="submit">Сохранить изменения</v-btn>
                  <v-btn text @click="closeEditModal">Отмена</v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
  
        <!-- Модальное окно предпросмотра -->
        <v-dialog v-model="showPreviewModal" max-width="650px">
        <v-card class="preview-modal">
            <v-card-text>
            <div class="preview-content">
                <div class="story-preview-container">
                    <!-- Заголовок сторис -->
                    <div class="page-indicators">
                    <span
                        v-for="(pg, idx) in (previewData.storyPages && previewData.storyPages.length
                        ? previewData.storyPages
                        : previewData.pages)"
                        :key="idx"
                        :class="['page-dot', { active: idx === previewCurrentPageIndex }]"
                    ></span>
                    </div>

                    <div
                        class="story-header"
                        :style="{ backgroundColor: previewData.headerColor || '#ccc' }"
                    >
                        <div class="story-logo">
                        <img v-if="previewLogo" :src="previewLogo" alt="Логотип" />
                        </div>
                        <h3 class="story-title">
                        {{ previewData.storyName || 'Без названия' }}
                        </h3>
                    </div>

                <!-- Многостраничный просмотр -->
                <div
                    v-if="(previewData.storyPages && previewData.storyPages.length) 
                        || (previewData.pages && previewData.pages.length)"
                    class="pages-preview"
                >
                    
                    <div class="page-navigation">
                    <v-btn
                        text
                        small
                        @click="prevPreviewPage"
                        :disabled="previewCurrentPageIndex === 0"
                        style="
                        width: 38px !important;
                        background-color: #E1E1E180;
                        height: 38px;
                        min-width: 38px;
                        border-radius: 50%;"
                    >
                    <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.77056 11.2579C5.91747 11.1028 6 10.8925 6 10.6732C6 10.4539 5.91747 10.2436 5.77056 10.0885L1.89154 5.99501L5.77056 1.90148C5.91331 1.74551 5.99229 1.53662 5.99051 1.31979C5.98872 1.10296 5.90631 0.895542 5.76102 0.742214C5.61572 0.588886 5.41918 0.501916 5.21371 0.500031C5.00824 0.498147 4.81029 0.581502 4.66249 0.732141L0.22944 5.41034C0.0825301 5.56542 5.00432e-07 5.77572 4.81262e-07 5.99501C4.62091e-07 6.21429 0.08253 6.4246 0.22944 6.57968L4.66249 11.2579C4.80945 11.4129 5.00873 11.5 5.21653 11.5C5.42432 11.5 5.62361 11.4129 5.77056 11.2579Z" fill="white"/>
                    </svg>
                    </v-btn>
                    <v-btn
                        text
                        small
                        @click="nextPreviewPage"
                        :disabled="previewData.storyPages
                        ? previewCurrentPageIndex === previewData.storyPages.length - 1
                        : previewCurrentPageIndex === previewData.pages.length - 1"
                        style="
                        width: 38px !important;
                        background-color: #E1E1E180;
                        height: 38px;
                        min-width: 38px;
                        border-radius: 50%;"
                    >
                    <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.229439 0.742127C0.0825295 0.897208 0 1.10751 0 1.3268C0 1.54608 0.0825295 1.75639 0.229439 1.91147L4.10846 6.00499L0.229439 10.0985C0.086693 10.2545 0.0077061 10.4634 0.00949156 10.6802C0.011277 10.897 0.0936919 11.1045 0.238985 11.2578C0.384278 11.4111 0.580825 11.4981 0.786293 11.5C0.991761 11.5019 1.18971 11.4185 1.33751 11.2679L5.77056 6.58966C5.91747 6.43458 6 6.22428 6 6.00499C6 5.78571 5.91747 5.5754 5.77056 5.42032L1.33751 0.742127C1.19055 0.587093 0.991266 0.5 0.783473 0.5C0.57568 0.5 0.376394 0.587093 0.229439 0.742127Z" fill="white"/>
                    </svg>
                    </v-btn>
                    </div>

                    <!-- Контейнер товаров -->
                    <div
                    class="preview-items-container"
                    :class="[
                        'count-' + (transformedPreviewPage.products.length || 0)
                    ]"
                    >
                    <div
                        v-for="prod in transformedPreviewPage.products"
                        :key="prod.id"
                        class="preview-item"
                    >
                        <img
                        :src="getProductImage(prod)"
                        alt="product"
                        />
                    </div>
                    </div>
                </div>
                <div v-else>
                    <p>Нет страниц или товаров для предпросмотра</p>
                </div>
                </div>
            </div>
            </v-card-text>
            <v-card-actions>
            <v-btn color="primary" @click="closePreviewModal">Закрыть</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

  
        <!-- =========== Модальное окно Удаления =========== -->
        <v-dialog v-model="showDeleteModal" max-width="500px">
          <v-card>
            <v-card-title>
              <h2>Подтверждение удаления</h2>
            </v-card-title>
            <v-card-text>
              <p>Вы уверены, что хотите удалить сторис "{{ storyToDelete.storyName }}"?</p>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" @click="deleteStoryConfirmed">Удалить</v-btn>
              <v-btn text @click="closeDeleteModal">Отмена</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  
      </div> <!-- /v-else -->
  
    </v-container>
  </template>
  
  <script>
  import { mapActions, mapGetters, mapState } from "vuex";
  
  export default {
    name: "StoriesPage",
  
    data() {
      return {
        // Показываем "скелетон" до момента, пока мы не загрузим userInfo и остальные данные
        isLoadingUser: true,
        showColorMenuCreate: false,
        showColorMenuEdit: false,
        filters: {
          status: "",
          providerId: null,
          departmentId: null,
        },
        filteredStories: [],
  
        // Создание
        showCreateModal: false,
        newStory: {
          providerId: null,
          departmentId: null,
          storyName: "",
          headerColor: "#FF5733",
          isActive: true,
          pages: [{ pageIndex: 0, productIds: [] }],
        },
        selectedNewProductForPage: [""],
        newLogoFile: null,
        newStoryLogoPreview: null,
  
        // Редактирование
        showEditModal: false,
        editStory: {
          pages: [],
        },
        selectedEditProductForPage: [],
        editLogoFile: null,
        editStoryLogoPreview: null,
  
        // Предпросмотр
        showPreviewModal: false,
        previewData: {},
        previewLogo: null,
        previewCurrentPageIndex: 0,
  
        // Удаление
        showDeleteModal: false,
        storyToDelete: {},
      };
    },
  
    computed: {
      
      ...mapState("User", ["userInfo"]),
  
      // Определяем, поставщик ли 
      isProvider() {
       
        // => userInfo.user?.role?.name === "Поставщик"
        if (!this.userInfo || !this.userInfo.user) return false;
        const roleName = this.userInfo.user.role?.name || "";
        return roleName === "Поставщик";
      },
  
      // Если пользователь — поставщик, делаем массив из одного элемента
      providerSelectSingle() {
        if (!this.userInfo || !this.userInfo.providerId) {
          return [];
        }
        const pid = this.userInfo.providerId; 
        return [
          {
            id: pid,
            label: `Поставщик (ID: ${pid})`,
          },
        ];
      },
  
      // Из модуля STORIES
      ...mapGetters("STORIES", ["ALL_STORIES"]),
  
      // Из модуля Providers
      ...mapState("Providers", ["allProviders", "providerProducts"]),
      // Из модуля Department
      ...mapState("Department", ["cityDepartment"]),
  
      // Приведение providerProducts => массив
      productsForSelect() {
        if (Array.isArray(this.providerProducts)) {
          return this.providerProducts.map((item) => ({
            id: item.providerProductId.id,
            name: item.providerProductId.name,
            images: item.providerProductId.images || [],
          }));
        } else if (
          this.providerProducts &&
          Array.isArray(this.providerProducts.content)
        ) {
          return this.providerProducts.content.map((item) => ({
            id: item.providerProductId.id,
            name: item.providerProductId.name,
            images: item.providerProductId.images || [],
          }));
        }
        return [];
      },
  
      // Список поставщиков (админу)
      providerSelectItems() {
        return this.allProviders.map((p) => ({
          id: p.id,
          label: `${p.name} (ID: ${p.id})`,
        }));
      },
  
      // Список филиалов
      departmentSelectItems() {
        return this.cityDepartment.map((d) => ({
          id: d.id,
          label: `${d.name} (ID: ${d.id})`,
        }));
      },
  
      // Список статусов
      statusItems() {
        return [
          { value: "", text: "Все статусы" },
          { value: true, text: "Активные" },
          { value: false, text: "Неактивные" },
        ];
      },
  
      // Для v-autocomplete (товары)
      productsForSelectItems() {
        return this.productsForSelect.map((prod) => ({
          id: prod.id,
          name: `${prod.name} (ID: ${prod.id})`,
        }));
      },
  
      // Предпросмотр
      currentPreviewPage() {
        if (this.previewData.storyPages && this.previewData.storyPages.length) {
          return this.previewData.storyPages[this.previewCurrentPageIndex] || null;
        } else if (this.previewData.pages && this.previewData.pages.length) {
          return this.previewData.pages[this.previewCurrentPageIndex] || null;
        }
        return null;
      },
      transformedPreviewPage() {
        const page = this.currentPreviewPage;
        if (!page) return null;
  
        let products = [];
        if (page.products && page.products.length) {
          products = page.products;
        } else if (page.productIds && page.productIds.length) {
          products = page.productIds.map((prodId) => {
            const product = this.productsForSelect.find((p) => p.id === prodId);
            return product
              ? product
              : { id: prodId, name: `Товар #${prodId}`, images: [] };
          });
        }
        return { ...page, products };
      },
    },
  
    methods: {
      // Экшены
      ...mapActions("STORIES", [
        "GET_ALL_STORIES",
        "CREATE_STORY",
        "UPDATE_STORY",
        "DELETE_STORY",
      ]),
      ...mapActions("Providers", [
        "GET_ALL_PROVIDERS",
        "GET_PROVIDER_PRODUCTS_WITH_FILTER",
      ]),
  
      async fetchDepartments() {
        const body = { cityId: 1, page: 1, size: 44 };
        await this.$store.dispatch("Department/GET_DEPARTMENT", body);
      },
  
      async fetchStories() {
        // Для отладки:
        console.log(">> userInfo:", this.userInfo);
        console.log(">> isProvider:", this.isProvider);
  
        // 1) Загружаем сторисы
        await this.GET_ALL_STORIES();
  
        // 2) Если поставщик => filters.providerId = this.userInfo.providerId
        if (this.isProvider && this.userInfo.providerId) {
          this.filters.providerId = this.userInfo.providerId;
        }
  
        // 3) Применяем фильтры
        this.applyFilters();
      },
  
      applyFilters() {
        const all = this.ALL_STORIES || [];
        this.filteredStories = all.filter((story) => {
          let match = true;
          // Статус
          if (this.filters.status !== "") {
            match =
              match &&
              story.isActive ===
                (this.filters.status === "true" || this.filters.status === true);
          }
          // Поставщик
          if (this.filters.providerId) {
            match = match && story.providerId === Number(this.filters.providerId);
          }
          // Филиал
          if (this.filters.departmentId) {
            match = match && story.departmentId === Number(this.filters.departmentId);
          }
          return match;
        });
      },
  
      // Получение названий
      getProviderNameById(id) {
        if (!id) return "Не выбран";
        const found = this.allProviders.find((p) => p.id === id);
        return found ? found.name : `Поставщик #${id}`;
      },
      getDepartmentNameById(id) {
        if (!id) return "Не выбран";
        const found = this.cityDepartment.find((d) => d.id === id);
        return found ? found.name : `Филиал #${id}`;
      },
  
      // ====== Создание ======
      openCreateModal() {
        this.resetNewStory();
        // Если мы поставщик => проставляем providerId
        if (this.isProvider && this.userInfo.providerId) {
          this.newStory.providerId = this.userInfo.providerId;
        }
        this.showCreateModal = true;
      },
      closeCreateModal() {
        this.showCreateModal = false;
      },
      handleCreateLogoChange(file) {
        if (!file) {
          this.newLogoFile = null;
          this.newStoryLogoPreview = null;
          return;
        }
        this.newLogoFile = file;
        const reader = new FileReader();
        reader.onload = (evt) => {
          this.newStoryLogoPreview = evt.target.result;
        };
        reader.readAsDataURL(file);
      },
      addNewPage() {
        if (this.newStory.pages.length >= 4) return;
        const newIndex = this.newStory.pages.length;
        this.newStory.pages.push({ pageIndex: newIndex, productIds: [] });
        this.selectedNewProductForPage.push("");
      },
      removeNewPage(pageIndex) {
        if (pageIndex === 0) return;
        this.newStory.pages.splice(pageIndex, 1);
        this.selectedNewProductForPage.splice(pageIndex, 1);
        this.newStory.pages.forEach((pg, idx) => {
          pg.pageIndex = idx;
        });
      },
      addProductToNewPage(pageIndex) {
        const prodId = this.selectedNewProductForPage[pageIndex];
        if (!prodId) return;
        const page = this.newStory.pages[pageIndex];
        if (!page.productIds.includes(prodId)) {
          if (page.productIds.length < 6) {
            page.productIds.push(prodId);
          } else {
            alert("Максимум 6 товаров на страницу");
          }
        }
        this.selectedNewProductForPage.splice(pageIndex, 1, "");
      },
      removeProductFromNewPage(pageIndex, prodId) {
        const page = this.newStory.pages[pageIndex];
        page.productIds = page.productIds.filter((id) => id !== prodId);
      },
      getProductName(prodId) {
        const prod = this.productsForSelect.find((p) => p.id === prodId);
        return prod ? prod.name : `Товар #${prodId}`;
      },
      async createStory() {
        try {
          if (!this.newLogoFile) {
            alert("Логотип обязателен");
            return;
          }
          const payload = {
            storyName: this.newStory.storyName,
            providerId: this.newStory.providerId,
            departmentId: this.newStory.departmentId,
            isActive: this.newStory.isActive,
            headerColor: this.newStory.headerColor,
            pages: this.newStory.pages,
          };
          await this.CREATE_STORY({ storyData: payload, file: this.newLogoFile });
          alert("Сторис успешно создан");
          this.closeCreateModal();
          await this.fetchStories();
        } catch (error) {
          console.error("Ошибка при создании сториса:", error);
          alert("Ошибка при создании сториса");
        }
      },
      resetNewStory() {
        this.newStory = {
          providerId: null,
          departmentId: null,
          storyName: "",
          headerColor: "#FF5733",
          isActive: true,
          pages: [{ pageIndex: 0, productIds: [] }],
        };
        this.selectedNewProductForPage = [""];
        this.newLogoFile = null;
        this.newStoryLogoPreview = null;
      },
        // Метод для переключения активности сториса
  toggleStatus(story) {
    const newStatus = !story.isActive;
    // Формируем payload, похожий на то, что используется при редактировании
    const updatedData = {
      storyName: story.storyName,
      providerId: story.providerId,
      departmentId: story.departmentId,
      isActive: newStatus,
      headerColor: story.headerColor,
      pages: story.pages || [], // или story.storyPages, если так хранится
    };
    // Вызываем Vuex-экшен для обновления сториса (файл здесь не передаём)
    this.UPDATE_STORY({ storyId: story.id, storyData: updatedData, file: null })
      .then(() => {
        alert(`Сторис теперь ${newStatus ? "активен" : "неактивен"}`);
        this.fetchStories();
      })
      .catch((error) => {
        console.error("Ошибка при переключении статуса сториса", error);
        alert("Ошибка при переключении статуса сториса");
      });
  },
      // ====== Редактирование ======
      async openEditModal(story) {
  const copy = JSON.parse(JSON.stringify(story));
        
  // Превращаем storyPages => pages
  if (copy.storyPages && copy.storyPages.length) {
    copy.pages = copy.storyPages.map(sp => ({
      pageIndex: sp.pageIndex,
      productIds: sp.products ? sp.products.map(pr => pr.id) : [],
    }));
  }
  if (!copy.pages || !copy.pages.length) {
    copy.pages = [{ pageIndex: 0, productIds: [] }];
  }
  copy.pages.forEach((pg, idx) => {
    pg.pageIndex = idx;
  });

  this.editStory = copy;
  this.editLogoFile = null;
  this.editStoryLogoPreview = copy.logoUrl || null;
  this.selectedEditProductForPage = copy.pages.map(() => "");

  // Если провайдер — фиксируем providerId (чтобы нельзя было менять)
  if (this.isProvider && this.userInfo.providerId) {
    this.editStory.providerId = this.userInfo.providerId;
  }

  // *** КЛЮЧЕВОЙ МОМЕНТ ***
  // Если у сториса есть providerId (и > 0), грузим товары для него
  if (this.editStory.providerId) {
    await this.GET_PROVIDER_PRODUCTS_WITH_FILTER({
      providerId: this.editStory.providerId,
      page: 0,
      size: 999,
      sortField: "NAME",
      sortOrder: "ASC",
      status: "APPROVED",
    });
  }

  this.showEditModal = true;
        },

      closeEditModal() {
        this.showEditModal = false;
      },
      handleEditLogoChange(file) {
        if (!file) {
          this.editLogoFile = null;
          this.editStoryLogoPreview = null;
          return;
        }
        this.editLogoFile = file;
        const reader = new FileReader();
        reader.onload = (evt) => {
          this.editStoryLogoPreview = evt.target.result;
        };
        reader.readAsDataURL(file);
      },
      onProviderChangeEdit() {
        if (this.editStory.providerId) {
          this.GET_PROVIDER_PRODUCTS_WITH_FILTER({
            providerId: this.editStory.providerId,
            page: 0,
            size: 2999,
            sortField: "NAME",
            sortOrder: "ASC",
            status:`APPROVED`
          });
        }
      },
      addEditPage() {
        if (this.editStory.pages.length >= 4) return;
        const newIndex = this.editStory.pages.length;
        this.editStory.pages.push({ pageIndex: newIndex, productIds: [] });
        this.selectedEditProductForPage.push("");
      },
      removeEditPage(pageIndex) {
        if (pageIndex === 0) return;
        this.editStory.pages.splice(pageIndex, 1);
        this.selectedEditProductForPage.splice(pageIndex, 1);
        this.editStory.pages.forEach((pg, idx) => {
          pg.pageIndex = idx;
        });
      },
      addProductToEditPage(pageIndex) {
        const prodId = this.selectedEditProductForPage[pageIndex];
        if (!prodId) return;
        const page = this.editStory.pages[pageIndex];
        if (!page.productIds.includes(prodId)) {
          if (page.productIds.length < 6) {
            page.productIds.push(prodId);
          } else {
            alert("Максимум 6 товаров на страницу");
          }
        }
        this.selectedEditProductForPage.splice(pageIndex, 1, "");
      },
      removeProductFromEditPage(pageIndex, prodId) {
        const page = this.editStory.pages[pageIndex];
        page.productIds = page.productIds.filter((id) => id !== prodId);
      },
      async updateStory() {
        try {
          const payload = {
            storyName: this.editStory.storyName,
            providerId: this.editStory.providerId,
            departmentId: this.editStory.departmentId,
            isActive: this.editStory.isActive,
            headerColor: this.editStory.headerColor,
            pages: this.editStory.pages,
          };
          await this.UPDATE_STORY({
            storyId: this.editStory.id,
            storyData: payload,
            file: this.editLogoFile || null,
          });
          alert("Сторис успешно обновлён");
          this.closeEditModal();
          await this.fetchStories();
        } catch (error) {
          console.error("Ошибка при обновлении сториса:", error);
          alert("Ошибка при обновлении сториса");
        }
      },
  
      // ====== Предпросмотр ======
      openCreatePreview() {
        this.previewData = JSON.parse(JSON.stringify(this.newStory));
        this.previewLogo = this.newStoryLogoPreview;
        this.previewCurrentPageIndex = 0;
        this.showPreviewModal = true;
      },
      openEditPreview() {
        this.previewData = JSON.parse(JSON.stringify(this.editStory));
        this.previewLogo = this.editStoryLogoPreview;
        this.previewCurrentPageIndex = 0;
        this.showPreviewModal = true;
      },
      openPreviewModal(story) {
        const copy = JSON.parse(JSON.stringify(story));
        if (!copy.pages && copy.storyPages) {
          copy.pages = copy.storyPages.map((sp) => ({
            pageIndex: sp.pageIndex,
            productIds: sp.products ? sp.products.map((pr) => pr.id) : [],
          }));
        }
        if (!copy.pages) {
          copy.pages = [];
        }
        this.previewData = copy;
        this.previewLogo = copy.logoUrl || null;
        this.previewCurrentPageIndex = 0;
        this.showPreviewModal = true;
      },
      closePreviewModal() {
        this.showPreviewModal = false;
        this.previewData = {};
        this.previewLogo = null;
      },
      prevPreviewPage() {
        if (this.previewCurrentPageIndex > 0) {
          this.previewCurrentPageIndex--;
        }
      },
      nextPreviewPage() {
        if (
          this.previewData.storyPages &&
          this.previewCurrentPageIndex < this.previewData.storyPages.length - 1
        ) {
          this.previewCurrentPageIndex++;
        } else if (
          this.previewData.pages &&
          this.previewCurrentPageIndex < this.previewData.pages.length - 1
        ) {
          this.previewCurrentPageIndex++;
        }
      },
      getProductImage(prod) {
        if (prod.images && prod.images.length) {
          return prod.images[0].fileUrlSmall || prod.images[0].fileUrl;
        }
        return null;
      },
  
      // ====== Удаление ======
      confirmDelete(story) {
        this.storyToDelete = story;
        this.showDeleteModal = true;
      },
      closeDeleteModal() {
        this.showDeleteModal = false;
        this.storyToDelete = {};
      },
      async deleteStoryConfirmed() {
        try {
          await this.DELETE_STORY(this.storyToDelete.id);
          alert("Сторис успешно удалён");
          this.closeDeleteModal();
          await this.fetchStories();
        } catch (error) {
          console.error("Ошибка при удалении сториса:", error);
          alert("Ошибка при удалении сториса");
        }
      },
  
      // ====== Хук для загрузки userInfo + данных =====
      async loadUserAndData() {
        // Если нужно самим грузить userInfo (не вызывается из App.vue), делаем:
        // if (!this.userInfo || !this.userInfo.user) {
        //   // Например: await this.$store.dispatch("User/GET_USER_INFO");
        // }
        // Затем грузим всё остальное:
        await this.GET_ALL_PROVIDERS();
        await this.fetchDepartments();
        await this.fetchStories();
  
        // Готово => скрываем skeleton
        this.isLoadingUser = false;
      },
    },
  
    async created() {
      console.log("### created => userInfo =>", this.userInfo);
  
      // Здесь мы имитируем загрузку userInfo и данных
      // Если userInfo уже загружен, можно проверить:
      // if (!this.userInfo || !this.userInfo.user) {
      //   await this.$store.dispatch("User/GET_USER_INFO");
      // }
  
      // Загружаем поставщиков, департаменты, сторисы
      await this.GET_ALL_PROVIDERS();
      await this.fetchDepartments();
      await this.fetchStories();
  
      // Когда всё подгрузили -> убираем skeleton
      this.isLoadingUser = false;
    },
  
    watch: {
      // Когда при создании сториса меняется providerId => подгружаем товары
      "newStory.providerId"(val) {
        if (val) {
          this.newStory.pages.forEach((pg) => {
            pg.productIds = [];
          });
          this.selectedNewProductForPage = this.newStory.pages.map(() => "");
          this.GET_PROVIDER_PRODUCTS_WITH_FILTER({ providerId: val,  page: 0,
            size: 2999,
            sortField: "NAME",
            sortOrder: "ASC",
            status:`APPROVED` });
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .stories-page {
    padding: 16px;
  }
  
  /* Фильтры */
  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
  }
  .stories-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  .story-card {
    background: #fafafa;
  }
  .card-logo {
    text-align: center;
    margin-bottom: 8px;
  }
  .card-logo img {
    max-height: 100px;
  }
  .card-info h3 {
    margin: 0 0 8px;
  }
  .color-indicator {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    border: 1px solid #ccc;
  }
  .card-actions {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .add-story-btn {
    margin-bottom: 16px;
  }
  /* Формы */
  .form-group {
    margin-bottom: 12px;
  }
  .form-group label {
    display: block;
    margin-bottom: 4px;
  }
  /* Превью логотипа */
  .logo-preview img {
    max-height: 100px;
    margin-top: 8px;
  }
  /* Выбранные товары */
  .selected-products {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  .selected-product {
    background: #eee;
    border: 1px solid #ccc;
    padding: 2px 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  .selected-product button {
    margin-left: 4px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: red;
  }
  .form-actions {
    display: flex;
    gap: 8px;
    margin-top: 16px;
  }
 
   
  .remove-page-btn {
    margin-top: 8px;
    background: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
    border-radius: 4px;
    cursor: pointer;
    padding: 4px 8px;
  }


 /* =========================
   Общие стили модального
   ========================= */
.preview-modal .preview-content {
  border: none;
  padding: 10px;
  background-color: #a4ddc2;
}

/* =========================
   Карточка сторис
   ========================= */
.story-preview-container {
  width: 393px;
  height: 691px;
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  overflow: hidden; /* чтобы картинки не вылезали */
}

/* Шапка (story-header) */
.story-header {
  height: 166px;
  border-radius: 0 0 20px 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.story-logo {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: 20px;
  top: 20px;
  border: 2px solid #fff;
}
.story-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.story-title {
  color: #fff;
  font-size: 18px;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
}

/* =========================
   Блок многостраничного просмотра
   (контейнер товаров + индикаторы + навигация)
   ========================= */
.pages-preview {
  /* «Сдвигаем» контент на 28px вверх,
     чтобы товары зашли под шапку */
  position: relative;
  top: -28px;
  height: calc(100% - 166px + 28px);
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  z-index: 2;
}

/* Индикаторы (точки) */
.page-indicators {
  position: absolute;
  top: 16px;
  left: 5%;        /* сдвигаем на 5% слева */
  width: 90%;      /* полоски занимают 90% ширины контейнера */
  display: flex;   /* флекс-контейнер */
  gap: 4px;        /* небольшой промежуток между полосками */
  align-items: center;
  height: 3px;     /* общая высота (толщина) полосок */
  z-index: 999;
}

/* Каждая «полоска» (dot) */
.page-dot {
  /* flex: 1 означает, что каждый .page-dot 
     будет автоматически растягиваться 
     на равную часть свободного пространства 
     внутри .page-indicators */
  flex: 1;
  height: 3px;
  background-color: #ccc;
}

/* Активная полоска */
.page-dot.active {
  background-color: #ffffff;
}

/* Кнопки «Назад/Вперёд» */
.page-navigation {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}

/* =========================
   Контейнер товаров
   ========================= */
.preview-items-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Каждый товар */
.preview-item {
  position: absolute;
  display: block;
  background-color: #ccc;
  overflow: hidden;
  border-radius: 12px;
}
.preview-item img {
    display: block;
  width: 100%;
  height: auto;     
  max-height: 100%;  
  object-fit: contain; 
  object-position: center; 
}

/* =========================
   Раскладка товаров
   (поднята ещё на 5px, сдвинута влево на 15px)
   ========================= */

/* ============= 1 товар (count-1) ============= */
.count-1 .preview-item:nth-child(1) {
  top: -13px;     /* было -8 => ещё на 5px выше */
  left: 15px;     /* было 30 => на 15px левее */
  width: 341px;
  height: 528px;
}

/* ============= 2 товара (count-2) ============= */
.count-2 .preview-item:nth-child(1) {
  top: -13px;     /* -8 => -13 */
  left: 15px;     /* 30 => 15 */
  width: 341px;
  height: 253px;
}
.count-2 .preview-item:nth-child(2) {
  top: 260px;     /* 265 => 260 */
  left: 15px;     /* 30 => 15 */
  width: 341px;
  height: 253px;
}

/* ============= 3 товара (count-3) ============= */
.count-3 .preview-item:nth-child(1) {
  top: -13px;     /* -8 => -13 */
  left: 15px;     /* 30 => 15 */
  width: 341px;
  height: 253px;
}
.count-3 .preview-item:nth-child(2) {
  top: 260px;     /* 265 => 260 */
  left: 15px;     /* 30 => 15 */
  width: 166px;
  height: 253px;
}
.count-3 .preview-item:nth-child(3) {
  top: 260px;     /* 265 => 260 */
  left: 188px;    /* 216 => 201 (216 - 15) */
  width: 166px;
  height: 253px;
}

/* ============= 4 товара (count-4) ============= */
.count-4 .preview-item:nth-child(1) {
  top: -13px;
  left: 15px;     /* 30 => 15 */
  width: 166px;
  height: 253px;
}
.count-4 .preview-item:nth-child(2) {
  top: -13px;
  left: 188px;    /* 216 => 201 */
  width: 166px;
  height: 253px;
}
.count-4 .preview-item:nth-child(3) {
  top: 260px;     /* 265 => 260 */
  left: 15px;
  width: 166px;
  height: 253px;
}
.count-4 .preview-item:nth-child(4) {
  top: 260px;
  left: 188px;
  width: 166px;
  height: 253px;
}

/* ============= 5 товаров (count-5) ============= 
   Первые 4 => 166×174, 
   Пятый => 341×165 
*/
.count-5 .preview-item:nth-child(1) {
  top: -13px;
  left: 15px;     /* 30 => 15 */
  width: 166px;
  height: 174px;
}
.count-5 .preview-item:nth-child(2) {
  top: -13px;
  left: 188px;    /* 216 => 201 */
  width: 166px;
  height: 174px;
}
.count-5 .preview-item:nth-child(3) {
  /* 186 => 181 */
  top: 181px;
  left: 15px;     /* 30 => 15 */
  width: 166px;
  height: 174px;
}
.count-5 .preview-item:nth-child(4) {
  top: 181px;
  left: 188px;    /* 216 => 201 */
  width: 166px;
  height: 174px;
}
.count-5 .preview-item:nth-child(5) {
  /* 380 => 375 */
  top: 375px;
  left: 11px;     /* 26 => 11 */
  width: 341px;
  height: 165px;
}

/* ============= 6 товаров (count-6) ============= 
   3 строки по 2, 166×165
*/
.count-6 .preview-item:nth-child(1) {
  top: -13px;
  left: 15px;     /* 30 => 15 */
  width: 166px;
  height: 165px;
}
.count-6 .preview-item:nth-child(2) {
  top: -13px;
  left: 188px;    /* 216 => 201 */
  width: 166px;
  height: 165px;
}
/* 2-я строка: 177 => 172 */
.count-6 .preview-item:nth-child(3) {
  top: 172px;
  left: 15px;
  width: 166px;
  height: 165px;
}
.count-6 .preview-item:nth-child(4) {
  top: 172px;
  left: 188px;
  width: 166px;
  height: 165px;
}
/* 3-я строка: 362 => 357 */
.count-6 .preview-item:nth-child(5) {
  top: 357px;
  left: 15px;
  width: 166px;
  height: 165px;
}
.count-6 .preview-item:nth-child(6) {
  top: 357px;
  left: 188px;
  width: 166px;
  height: 165px;
}

  </style>
  